@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=DM+Sans:opsz@9..40&family=Jost:wght@500&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@600&family=Pacifico&family=Poppins:wght@500&display=swap");



.admin_user_list_table {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.admin_user_list_name {
  font-family: mulish;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.57px;
  color: rgba(0, 0, 0, 1) !important;
}
.admin_user_list_gmail {
  font-family: mulish;
  font-weight: 500;
  font-size: 12px;
  line-height: 15.06px;
  color: rgba(142, 142, 161, 1);
}
.admin_user_list_name_gmail {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}
.admin_user_list_date{
    font-family: jost !important;
    font-size: 13px !important;
    line-height: 18.79px !important;
}