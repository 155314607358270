@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}

.login_page_reset_password {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}

.login_div {
  font-weight: 600 !important;
  font-size: 26px;
  line-height: 37px;
  font-family: jost;
}

.email_address_login {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  font-family: jost !important;
}

.reset_login_page {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: jost;
}

.remember_login_page {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: jost;
}

.login_btn_btn {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  font-family: jost;
}

.have_account {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  font-family: jost;
}

.reset_button {
  color: rgba(226, 19, 34, 1) !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

.reset_button:hover {
  color: rgba(226, 19, 34, 1) !important;
}

.login_form_class {
  margin-top: 4rem;
  /* margin-left: 6rem;
  margin-bottom: 3rem; */
}

.input_login_page {
  background-color: rgba(247, 247, 248, 1) !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.input_login_page:active {
  background-color: rgba(247, 247, 248, 1) !important;
  border: none !important;
  box-shadow: none !important;
}

.input_login_page:focus {
  background-color: rgba(247, 247, 248, 1) !important;
  border: none !important;
  box-shadow: none !important;
}

.my_eye_icon {
  border: none !important;
  cursor: pointer !important;
}

.login_for_line {
  position: relative;
}

.login_for_line::after {
  content: "";
  position: absolute;
  background-color: #be202f;
  width: 4px;
  height: 54%;
  top: 33%;
}

.reset_for_line {
  position: relative;
}

.reset_for_line::after {
  content: "";
  position: absolute;
  background-color: #be202f;
  width: 4px;
  height: 64%;
  top: 21%;
  left: 110%;
}

.reset_password_right_section {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100vh;
}

.text_fild_login div label {
  margin-top: -4px !important;
}

.text_fild_login div div input {
  padding: 11.5px 14px !important;
}